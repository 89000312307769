body {
	font-family: Assistant, sans-serif;
	line-height: 1.6rem;
	font-size: 1rem;
	font-weight: 400;
	overflow-x: hidden;
}

.page-wrapper-full {
	color: #313131;
	display: block;
	overflow-x: hidden;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	color: #510c38;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 300;
	text-transform: uppercase;
}

h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #510c38;
	font-size: 1.7rem;
	line-height: 2.3rem;
	font-weight: 600;
}

h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #510c38;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 600;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #510c38;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #510c38;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #510c38;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1.125rem;
	line-height: 1.6rem;
}

body a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #721926;
	text-decoration: none;
}

body a:hover {
	color: #797676;
	text-decoration: none;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 20px;
	font-size: 1.125rem;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 20px;
	font-size: 1.125rem;
}

li {
	margin-bottom: 0.25rem;
}

img {
	display: inline-block;
	max-width: 100%;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	font-weight: 600;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding: 1rem 1.5rem;
	border-left: 5px solid #ff9934;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	padding: 7px 1.15rem 8px;
	background-color: #510c38;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	-webkit-appearance: none;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 7px 1.15rem 8px !important;
	border: 0 none !important;
}

body a.primary,
body a.secondary,
body a.tertiary {
	color: #fff ;
	line-height: 1.6rem;
	cursor: pointer;
}

body a.tertiary {
	color: #313131 ;
	cursor: pointer;
}

.button:hover {
	background-color: #313131;
	color: #fff;
	cursor: pointer;
}

body a.primary:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	background-color: #a0176e !important;
	color: #fff;
	cursor: pointer;
}

body a.secondary,
.button.secondary,
.secondary,
button[type="submit"].secondary {
	background-color: #ff9934;
	color: #510c38;
	cursor: pointer;
}

.button.secondary:hover {
	background-color: #fbb703;
	cursor: pointer;
}

body a.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #fbb703 !important;
	color: #510c38;
	cursor: pointer;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	min-width: 0px;
	padding: 0.25rem 0.75rem;
	border: 1px solid #797676;
	background-color: #fff;
	color: #313131;
	font-size: 0.9rem;
}

.tertiary,
button[type="submit"].tertiary {
	padding: 0.25rem 0.75rem !important;
	border: 1px solid #797676 !important;
}

.button.tertiary:hover {
	background-color: #c9c6c4;
	cursor: pointer;
}

.tertiary:hover,
button[type="submit"].tertiary:hover {
	color: #313131;
	background-color: #c9c6c4 !important;
	cursor: pointer;
}

.button.header-search-button {
	width: 2rem;
	height: 2rem;
	min-width: 0px;
	margin-top: 0em;
	margin-right: 0rem;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	background-color: #a09e9c;
	background-image: url('/20180828132855/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 1rem;
	line-height: 1em;
	text-transform: uppercase;
	border: 0 none;
	cursor: pointer;
	border-radius: 0;
}

.button.header-search-button:hover {
	background-color: #6b6b6b;
	cursor: pointer;
}

.button.intro-cbox-learn-more {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	background-color: #510c38;
}

.button.intro-cbox-learn-more:hover {
	background-color: #791254;
}

.button.intro-cbox-learn-more.lower-cb-learn-more {
	background-color: #ff9934;
	color: #222221;
	font-weight: 700;
}

.button.intro-cbox-learn-more.lower-cb-learn-more:hover {
	background-color: #fbb703;
	cursor: pointer;
}

.container {
	max-width: 1900px;
	padding-right: 20px;
	padding-left: 20px;
}

.container.home-cb-container {
	max-width: 100%;
	padding: 2vw 4vw 6vw;
	background-color: #510c38;
	background-image: url("/20191004141900/assets/images/purple-pattern-2.png");
	background-position: 0px 0px;
}

.container.home-cb-container.home-intro-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 500px;
	padding-top: 4vw;
	padding-bottom: 4vw;
	background-color: #fbb703;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
	background-image: linear-gradient(180deg, #fff, #fff);
}

.container.home-cb-container.lower-cta-container {
	padding: 8vw 12vw;
	background-color: transparent;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.fixed-image {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.container.home-cb-container.featured-container {
	padding-top: 2vw;
	padding-bottom: 2vw;
	border-bottom-style: none;
	background-color: #510c38;
	background-image: url("/20191004141900/assets/images/purple-pattern-2.png");
	background-position: 0px 0px;
}

.container.footer-container {
	display: block;
	max-width: 1900px;
	padding-top: 2rem;
	padding-right: 4vw;
	padding-left: 4vw;
	border-radius: 5px solid #ff9934;
	background-color: #510c38;
	background-image: url("/20191004141900/assets/images/purple-pattern-2.png");
	background-position: 0px 0px;
	color: #000;
}

.container.footer-container.signoff-container {
	display: block;
	padding-right: 11vw;
	padding-left: 11vw;
	border-top-style: none;
	background-color: #3d082a;
	background-image: none;
	background-position: 0px 0px;
	border: 0;
}

.container.inside-container {
	min-height: 1px;
	max-width: 1500px;
	padding: 1vw 4vw 3vw;
}

.nav-section {
	background-color: transparent;
	display: block;
}

.nav-container {
	width: 100%;
	max-width: 100%;
	padding-right: 4vw;
	padding-left: 4vw;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
}

.nav-link {
	display: inline-block;
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-image: url('/20180828132855/assets/images/tab-01.svg');
	background-position: 50% 125%;
	background-size: 50px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
	color: #313131;
	line-height: 1.375rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.nav-link:hover {
	color: rgba(9, 49, 76, 0.5);
	cursor: pointer;
}

.nav-link.w--current {
	background-position: 50% 104%;
	color: #6b6b6b;
	cursor: pointer;
}

.nav-link.w--current:hover {
	color: #677179;
	cursor: pointer;
}

.nav-menu-wrap {
	margin-top: 16px;
	margin-bottom: 16px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	padding-left: 0;
	border-left: 1px none rgba(0, 0, 0, 0.15);
	font-size: 1.1rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: right;
}

.logo-link {
	top: -19px;
	display: block;
	width: 400px;
	padding-left: 0px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition-property: none;
	transition-property: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
}

.logo-link:hover {
	color: #fff;
	cursor: pointer;
}

.logo-link.w--current {
	display: block;
}

.section-wrap.inside-content-section {
	background-color: #510c38;
}

.footer-section {
	position: relative;
	padding-top: 0rem;
	background-color: #510c38;
	font-size: 0.9375rem;
	line-height: 1.5em;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.row.home-cbox-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 2rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.column.home-cbox-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.column.footer-signoff-right-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logo-image {
	max-width: 400px;
}

.footer-signoff-section {
	margin-top: 0;
	padding-top: 0rem;
	padding-bottom: 2rem;
	border-bottom: 1px none #000;
	background-color: #3d082a;
	color: #8d999f;
	font-size: 0.6875rem;
	line-height: 1rem;
	font-weight: 400;
}

.copyright-text {
	display: inline-block;
	margin-right: 0rem;
	margin-left: 0rem;
	color: #fff;
}

.copyright-text h1 {
	display: inline-block;
	margin: 0;
	padding: 0;
	color: #fff;
	font-size: 0.6875rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: none;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 1rem;
	padding-left: 0px;
	color: #6b6b6b;
	font-size: 0.6875rem;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	margin-right: 1em;
	margin-left: 1em;
	padding-left: 0;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #999;
	font-size: 0.5rem;
	line-height: 0.5625rem;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #999;
	font-weight: 600;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
	transition: all 300ms ease;
	opacity: 0.6;
}

#GRIPFooterLogo:hover {
	opacity: 1;
}

.footer-column-wrap {
	display: block;
	margin-bottom: 1rem;
	padding-right: 0em;
	padding-bottom: 1rem;
	clear: both;
}

.footer-column-wrap.centered-footer-column {
	text-align: center;
}

.footer-column-title {
	margin-top: 1rem;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	color: #ff9934;
	font-size: 1.375em;
	line-height: 1.25em;
	font-style: normal;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-column-title a {
	color: inherit;
	border-bottom: 1px solid transparent;
}

.footer-column-title a:hover {
	color: #fbb703;
	border-bottom-color: #fbb703;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 48px;
	padding-right: 4vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.slider-mask {
	padding-bottom: 220px;
	background-color: #510c38;
	background-image: url("/20191004141900/assets/images/purple-pattern-2.png");
	background-position: 50% 50%;
}

.header-contents-wrap {
	position: relative;
	z-index: 4500;
	display: block;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1rem;
	padding-top: 0.25em;
	padding-left: 0px;
	color: #313131;
	font-size: 0.8rem;
	line-height: 1rem;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	background-color: transparent;
	font-family: inherit;
}

.form-wrap {
	margin-bottom: 0px;
	font-family: Nunito;
	font-size: 1rem;
	font-weight: 400;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
	font-weight: 400;
}

.form-field.header-search-field {
	width: 270px;
	height: 2rem;
	margin-bottom: 0em;
	padding-right: 1em;
	padding-left: 1em;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(30, 2%, 62%, 0.75);
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #000;
	font-size: 0.75em;
	line-height: 1.25em;
	font-weight: 400;
	border-radius: 0;
}

.form-field.header-search-field:focus {
	background-color: #fff;
	font-family: inherit;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #48a700;
	background-color: #caf3aa;
	font-family: Assistant, sans-serif;
	color: #000;
	font-size: 1.125rem;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #721926;
	background-color: #ffccd0;
	font-family: Assistant, sans-serif;
	color: #000;
	font-size: 1.125rem;
	font-weight: 400;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-right: 6px;
	margin-bottom: 0rem;
	padding-left: 0em;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-size: 1rem;
	line-height: 1.25em;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
	color: #fb9a03;
}

.current-breadcrumb {
	color: #fbb703;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: 0 1px 0 0 #fb9a03;
}

.left-nav-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #fb9a03;
	font-size: 1rem;
}

.left-nav-list-link {
	display: block;
	padding: 0.65rem 1rem 0.65rem 0.5rem;
	background-image: url('/20180828132855/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: -10px 50%;
	background-size: 7px 10px;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 200ms ease, padding 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, padding 200ms ease;
	color: #510c38;
	line-height: 1.3rem;
}

.left-nav-list-link:hover {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	background-image: url('/20180828132855/assets/images/angle-right_6f6e6d.svg');
	background-position: 0px 50%;
	background-size: 7px;
	color: #fb9a03;
}

.left-nav-list-link.w--current,
.left-nav-list-link.selected {
	padding-right: 0.4rem;
	padding-left: 0.75em;
	background-image: url('/20180828132855/assets/images/angle-right_6f6e6d.svg');
	background-position: 0px 50%;
	background-size: 7px;
	background-repeat: no-repeat;
	-webkit-transition-property: none;
	transition-property: none;
	color: #721926;
	font-weight: 600;
}

.left-nav-list-link.w--current:hover,
.left-nav-list-link.selected {
	color: #721926;
	cursor: pointer;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em 0em 0em 0.75em;
	border-top: 1px solid #fb9a03;
	background-color: #f5f5f5;
}

.left-nav-nested-list.great-grandchild-nested-list {
	background-color: #ededed;
	font-family: inherit;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
	box-shadow: 0 1px 0 0 #fb9a03;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75rem 1rem 0.6875rem 0.5rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: padding 200ms ease, background-color 200ms ease, color 200ms ease;
	transition: padding 200ms ease, background-color 200ms ease, color 200ms ease;
	color: #510c38;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 400;
}

.left-nav-nested-list-link:hover {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	background-image: url('/20180828132855/assets/images/angle-right_6f6e6d.svg');
	background-position: 0px 50%;
	background-size: 6px;
	background-repeat: no-repeat;
	color: #fb9a03;
}

.left-nav-nested-list-link.w--current,
.left-nav-nested-list-link.selected {
	padding-right: 0.4rem;
	padding-left: 0.75rem;
	background-image: url('/20180828132855/assets/images/angle-right_6f6e6d.svg');
	background-position: 0px 50%;
	background-size: 6px;
	background-repeat: no-repeat;
	color: #721926;
	font-weight: 600;
}

.left-nav-nested-list-link.w--current:hover,
.left-nav-nested-list-link.selected:hover {
	color: #721926;
	cursor: pointer;
}

.right-side-col .box,
.right-col-item-wrap {
	position: relative;
	margin-bottom: 1.5rem;
	margin-left: 0vw;
	padding-top: 0.75rem;
	border-top: 3px solid #ff9934;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.right-side-col .contentbox_item .contentbox_item_image {
	width: 32px;
}

.slideshow-container {
	position: relative;
	width: 100%;
}

.slider {
	display: block;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-mask {
	background-color: #fff;
}

.slider-mask {
	padding-bottom: 220px;
	background-color: #510c38;
	background-image: url("/20191004141900/assets/images/purple-pattern-2.png");
	background-position: 50% 50%;
}

.slider-arrow {
	left: auto;
	top: auto;
	right: 3%;
	bottom: 7.5rem;
	display: block;
	width: 3.5rem;
	height: 2.5rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 100%, 0.25);
	border-radius: 0em;
	background-color: #ff9934;
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #fff;
	font-size: 0.875rem;
}

.has-caption.slider-arrow {
	bottom: 7.5rem;
}

.slider-arrow:hover {
	background-color: #fbb703;
	opacity: 1;
	color: #fff;
}

.slider-arrow.left-slider-arrow {
	display: block;
	margin-right: 3.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 100%, 0.25);
	background-color: #ff9934;
	opacity: 1;
}

.slider-arrow.left-slider-arrow:hover {
	background-color: #fbb703;
	cursor: pointer;
}

.slider-arrow-icon {
	color: #510c38;
}

.slider-indicator {
	left: auto;
	right: 3%;
	bottom: 7.5rem;
	display: block;
	width: auto;
	height: 2.5rem;
	margin-right: 7.5rem;
	margin-left: auto;
	padding: 1rem 1rem 0rem;
	border-radius: 5em;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#ff9934), to(#ff9934));
	background-image: linear-gradient(180deg, #ff9934, #ff9934);
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #fbb703;
	font-size: 0.5rem;
	line-height: 1em;
	text-align: right;
}

.has-caption.slider-indicator {
	bottom: 7.5rem;
}

.home-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 15vw;
	max-height: 200px;
	min-height: 160px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #454545;
	background-image: url('/20180828132855/assets/images/slide-05.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link.large-image-link {
	position: relative;
	overflow: visible;
	height: 18vw;
	max-height: 400px;
	min-height: 200px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-image: url('/20180828132855/assets/images/placeholder-image.png');
	font-family: Rajdhani;
	color: #fff;
	font-size: 1.75rem;
	line-height: 1em;
	font-weight: 500;
	text-shadow: 0 2px 3px rgba(5, 34, 53, 0.3);
}

.home-image-link.large-image-link.large-image-1 {
	background-image: url('/20180828132855/assets/images/6_578x325tinified.jpg');
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link.large-image-link.large-image-2 {
	background-image: url('/20180828132855/assets/images/8_578x325.jpg');
}

.home-image-link.large-image-link.large-image-3 {
	background-image: url('/20180828132855/assets/images/28_578x325.jpg');
}

.home-image-link.large-image-link.large-image-4 {
	background-image: url('/20180828132855/assets/images/11_578x325.jpg');
}

.home-image-link.large-image-link.large-image-5 {
	background-image: url('/20180828132855/assets/images/7_578x325.jpg');
}

.home-image-link.large-image-link.large-image-6 {
	background-image: url('/20180828132855/assets/images/1_578x325.jpg');
}

.home-content-box-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.home-content-box-wrap.large-content-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.home-content-box-title {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-size: 1.375em;
	line-height: 1.375em;
}

.home-content-box-title.intro-cb-title {
	color: #510c38;
	font-size: 2.75rem;
	line-height: 3.25rem;
	font-weight: 400;
}

.home-content-box-title.intro-cb-title.lower-cta-title {
	color: #ff9934;
	font-weight: 400;
}

.slider-caption-wrap {
	position: absolute;
	z-index: 5;
	display: block;
	width: 100%;
	max-width: 70%;
	margin-bottom: 1rem;
	padding: 0.5rem 4vw 1rem;
	color: rgba(240, 246, 248, 0.9);
	font-size: 1.125rem;
	line-height: 1.5em;
	text-align: right;
}

.inside-page-header-content-wrap {
	position: relative;
	margin-bottom: 1em;
	padding-bottom: 0em;
	border-bottom: 1px none #d5e1e6;
}

.inside-page-header-content-wrap h1 {
	color: #ff9934;
}

.inside-page-header-section {
	position: relative;
	display: block;
	height: 300px;
	background-color: #c9c6c4;
	background-image: url('/20180828132855/assets/images/banner.jpg');
	background-position: 0px 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.main-content-wrapper {
	overflow: hidden;
	padding-top: 0.6rem;
	width: 100%;
}

.main-content-wrapper.no-sidebars {
	font-size: 1.125rem;
}

.intro_text_style,
.intro-text-style {
	margin-bottom: 0.75rem;
	color: #313131;
	font-size: 1.35rem;
	line-height: 1.95rem;
}

.home-cb-text-wrap {
	width: 100%;
	padding: 0.5rem 0.125em;
}

.home-cb-text-wrap.intro-cbox-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0rem 0em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.0625rem;
	width: calc(66.66666667% - 40px);
}

.home-custom-cb-background {
	width: calc(33.33333333% - 10px);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-height: 280px;
	margin-left: 50px;
}

.home-cb-text-wrap.lower-cta-wrap {
	padding: 3vw 4.5vw 5vw 6vw;
	background-color: rgba(81, 12, 56, 0.9);
}

.slider-caption-wrap h1,
.slider-caption-wrap h2,
.slider-caption-wrap h3,
.slider-caption-title p {
	margin-top: 0.75rem;
	margin-bottom: 1rem;
	float: left;
	color: #ff9934;
	font-size: 1.75rem;
	line-height: 2.5rem;
	font-weight: 600;
	text-align: left;
	letter-spacing: 0em;
}

.footer-social-icon-link {
	margin: 4px 6px 8px;
	border-radius: 50%;
	background-color: #ff9934;
	opacity: 0.7;
}

.footer-social-icon-link:hover {
	opacity: 1;
}

.footer-social-icon-image {
	width: 32px;
	height: 32px;
	padding: 4px;
}

.left-nav-wrapper {
	display: block;
	height: auto;
	margin-right: 2.25vw;
	padding-top: 0em;
	padding-bottom: 4vw;
	border-top: 3px solid #fb9a03;
	border-right: 1px none #d5e1e6;
}

.left-sidebar-custom {
	margin-right: 2.25vw;
}

.footer-link {
	display: inline-block;
	border-bottom: 1px solid #797676;
	color: #fff;
}

.footer-link:hover {
	border-bottom-color: transparent;
	color: #fb9a03;
}

.left-sidebar-custom a,
.content-wrapper a,
.text-link {
	border-bottom: 1px solid #797676;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	color: #313131;
	cursor: pointer;
}

.left-sidebar-custom a:hover,
.content-wrapper a:hover,
.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #fb9a03;
}

.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.text-link.breadcrumb-link {
	display: inline-block;
	border-bottom-color: transparent;
	color: #ff9934;
}

.text-link.breadcrumb-link:hover {
	border-bottom-color: #c5d7df;
	color: #fbb703;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #c9c6c4;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 10%;
	margin-left: 10%;
}

.nav-drop-wrap {
	position: relative;
	margin-right: 4px;
	margin-left: 4px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-right: 1px none rgba(0, 0, 0, 0.15);
	margin-bottom: 0;
}

.nav-drop-wrap.last-nav-drop-wrap {
	margin-right: 0px;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.nav-drop-toggle.w--open {
	box-shadow: none;
}

.nav-drop-icon {
	display: none;
	margin-right: 2rem;
	font-size: 0.75em;
}

.nav-drop-list {
	overflow: hidden;
	display: block;
	height: 0;
	opacity: 0;
}

.open .nav-drop-list {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: rgba(81, 12, 56, 0.9);
	color: #fff;
	opacity: 1;
	height: auto;
	min-width: 150px;
	transition: opacity 500ms ease;
}

.nav-drop-wrap.open:last-of-type .nav-drop-list {
	left: auto;
	right: 0;
}

.nav-drop-list ul {
	padding-left: 0;
	margin: 0;
}

.nav-drop-list li {
	margin: 0;
}

.nav-drop-list a {
	margin-bottom: 1px;
	padding: 0.75em 0.75rem 0.75em 0.5rem;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.375em;
	font-weight: 400;
	display: block;
	white-space: nowrap;
}

.nav-drop-list a:hover {
	background-color: rgba(0, 0, 0, 0.3);
	color: hsla(0, 0%, 100%, 0.95);
	font-size: 0.875rem;
	font-weight: 400;
}

.nav-drop-list a.w--current {
	padding-right: 0.5rem;
	padding-left: 0.75rem;
	background-color: rgba(0, 0, 0, 0.3);
	background-image: url('/20180828132855/assets/images/angle-right_white_75.svg');
	background-position: 0px 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: #fff;
}

.nav-drop-list a.w--current:hover {
	background-size: 12px 12px;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 0.5rem 1.75vw;
	color: #ff9934;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	display: inline-block;
}

.open .nav-drop-toggle-link,
.nav-drop-toggle-link:hover {
	box-shadow: inset 0 -2px 0 0 #fbb703;
	color: #fbb703;
}

.nav-drop-toggle-link.active-item {
	box-shadow: inset 0 -2px 0 0 #fbb703;
	color: #fbb703;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-search-wrap {
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: transparent;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-mobile-search {
	display: block;
	overflow: hidden;
	height: 0px;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #21272e;
}

.nav-search-button-icon {
	width: 1.125em;
	height: 1.375em;
}

.nav-search-button-close-icon {
	display: none;
	width: 1em;
	height: 1.375em;
}

.footer-social-icons-links-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.footer-signoff-back-top-button {
	margin-left: 1.5rem;
	padding: 0.75em 2.5em 0.75em 1em;
	background-image: url('/20180828132855/assets/images/Arrow2_up_blk.svg');
	background-position: 75% 50%;
	background-size: 7px 5px;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 0 1px #797676;
	opacity: 0.65;
	color: #313131;
	font-size: 0.75rem;
	line-height: 1.25em;
	font-weight: 500;
	text-transform: uppercase;
}

.footer-signoff-back-top-button:hover {
	box-shadow: inset 0 0 0 1px #a09e9c;
	opacity: 1;
}

.home-image-link-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.75em;
	padding-bottom: 1.5em;
	padding-left: 0.75em;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	opacity: 0.4;
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
}

.last-content-box {
	position: relative;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.home-image-link-overlay-arrow {
	position: relative;
	display: none;
	width: 1em;
	height: 1em;
	padding: 4px;
	background-image: url('/20180828132855/assets/images/Arrow3_right_blk.svg');
	background-position: 50% 50%;
	background-size: 65% 65%;
	background-repeat: no-repeat;
	opacity: 0.5;
}

.home-image-link-text {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 0.75em 14% 0.75em 7%;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background-color: transparent;
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.85), rgba(9, 49, 76, 0) 64%);
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(9, 49, 76, 0) 64%);
	box-shadow: inset 0 0 25px 0 rgba(0, 0, 0, 0.1);
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
	font-family: Assistant, sans-serif;
	color: #ff9934;
	font-size: 1.75rem;
	line-height: 2rem;
	font-weight: 400;
}

.home-image-link-text:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.footer-contact-label-span {
	display: inline-block;
	min-width: 40px;
}

.slide-caption-link {
	padding-right: 1rem;
	float: left;
	clear: both;
	background-image: url('/20180828132855/assets/images/angle-right_6f6e6d.svg');
	background-position: 100% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
	color: #ff9934;
	font-weight: 600;
	font-size: 1.1rem;
	line-height: 1.5rem;
	text-align: left;
	text-transform: uppercase;
}

.slide-caption-link.no-caption {
	margin-top: 35px;
}

.slide-caption-link:hover {
	padding-right: 1.5rem;
	color: #fbb703;
}

.h2 {
	font-family: Nunito;
	line-height: 1.25em;
}

.cb-h2 {
	color: #ff9934;
	font-size: 1.75rem;
	font-weight: 400;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.home-cb-text-wrap p,
.home-cb-text-wrap.lower-cta-wrap p,
.cta-paragraph {
	max-width: none;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.home-cb-text-wrap.lower-cta-wrap p,
.cta-paragraph.lower-cta-paragraph {
	color: #fff;
	font-family: inherit;
}

.text-block-2 {
	color: #313131;
	font-weight: 600;
}

.footer-paragraph {
	color: #fff;
	font-family: inherit;
}

.footer-paragraph.phone-paragraph {
	margin-bottom: 0.25rem;
}

.header-section {
	position: fixed;
	top: 0px;
	width: 100%;
	background-color: #510c38;
	background-image: url("/20191004141900/assets/images/purple-pattern-2.png");
	background-position: 0px 0px;
}

.slide-image {
	display: block;
	width: 100%;
}

.inside-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 2rem;
	background-color: #fff;
}

.left-nav-col {
	padding-bottom: 4vw;
	width: 20%;
	height: 100%;
	min-width: 220px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: transparent;
	float: left;
}

.inside-content-column {
	width: 100%;
	overflow: hidden;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-side-col {
	width: 21%;
	margin-left: 4%;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.left-sidebar-custom h1,
.left-sidebar-custom h2,
.right-side-col h2 {
	margin-top: 0.25rem;
	color: #721926;
	font-size: 1.4rem;
	line-height: 1.8rem;
	font-weight: 600;
}

.left-sidebar-custom p,
.right-side-col p {
	font-size: 1rem;
	line-height: 1.4rem;
}

.left-sidebar-custom h3,
.right-side-col h3 {
	margin-top: 0.25rem;
	color: #721926;
	font-size: 1.4rem;
	line-height: 1.8rem;
	font-weight: 600;
}

.left-sidebar-custom h4,
.right-side-col h4 {
	margin-top: 0.25rem;
	color: #721926;
	font-size: 1.4rem;
	line-height: 1.8rem;
}

.right-col-link {
	border-bottom: 1px solid #797676;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	color: #313131;
	font-size: 1rem;
	line-height: 1.4em;
	font-weight: 400;
}

.right-col-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #721926;
}

.right-col-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.header-link {
	border-bottom: 1px solid #797676;
	color: #721926;
}

.header-link:hover {
	border-bottom-color: transparent;
	color: #797676;
	cursor: pointer;
}

.phone-link {
	color: #313131;
	cursor: pointer;
}

.phone-link.w--current {
	color: #313131;
	cursor: pointer;
}

.ecomm-header-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	padding-left: 0px;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #313131;
	font-size: 0.6875rem;
	line-height: 1.125em;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.ecomm-header-link:hover {
	color: #721926;
	cursor: pointer;
}

.ecomm-header-link.cart-icon {
	margin-right: 0;
	padding-left: 24px;
	background-image: url('/20180828132855/assets/images/shopping-cart_fa_797676.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	-webkit-transition-property: color;
	transition-property: color;
	color: #6b6b6b;
}

.ecomm-header-link.cart-icon:hover {
	background-image: url('/20180828132855/assets/images/shopping-cart_fa_721926.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	color: #721926;
}

.ecomm-header-link.account-icon {
	width: 22px;
	height: 20px;
	margin-right: 1rem;
	background-image: url('/20180828132855/assets/images/user_fa_797676.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	-webkit-transition-property: color;
	transition-property: color;
}

.ecomm-header-link.account-icon:hover {
	background-image: url('/20180828132855/assets/images/user_fa_721926.svg');
	background-size: 20px 20px;
}

.ecomm-link-text {
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.4rem;
	float: left;
	font-size: 0.773rem;
	font-weight: 600;
}

.ecomm-link-text:hover {
	color: #721926;
	cursor: pointer;
}

.ecomm-header-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-quick-link {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #fff;
	line-height: 1.6rem;
	text-transform: capitalize;
}

@media (max-width: 767px) {
	.footer-quick-link {
		padding: 14px 5px;
	}
}

.footer-quick-link:hover {
	border-bottom: 1px solid #797676;
	color: #fb9a03;
}

.footer-quick-link.selected {
	border-bottom-color: #797676;
	color: #fff;
}

.top {
	width: 100%;
	height: 0px;
}

.text-block-5 {
	color: #797676;
	font-family: inherit;
}

.slide-image-wrap {
	width: 100%;
	height: 100%;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.750000000000001;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="nav-section-on-page-load"] {
	-webkit-transform: translate(0px, -50px);
	-ms-transform: translate(0px, -50px);
	transform: translate(0px, -50px);
}

html.w-mod-js *[data-ix="nav-container-on-load"] {
	height: 60px;
}

html.w-mod-js *[data-ix="header-contents-wrap-on-page-load"] {
	opacity: 0;
}

html.w-mod-js *[data-ix="home-scroll-animation"] {
	opacity: 0;
}

html.w-mod-js *[data-ix="logo-image-on-load"] {
	width: 100%;
}

html.w-mod-js *[data-ix="logo-image-sticky"] {
	-webkit-transform: translate(0px, -100px);
	-ms-transform: translate(0px, -100px);
	transform: translate(0px, -100px);
}

html.w-mod-js *[data-ix="slidecaptionheader-2"] {
	opacity: 0;
	-webkit-transform: scale(0.7000000000000005, 0.7000000000000005);
	-ms-transform: scale(0.7000000000000005, 0.7000000000000005);
	transform: scale(0.7000000000000005, 0.7000000000000005);
}

html.w-mod-js *[data-ix="slide-learn-more"] {
	opacity: 0;
}

.footer-phone-link {
	display: none;
}

.footer-phone-text {
	display: inline-block;
}

.search-trigger {
	background-image: url("/20180828132855/assets/images/search_721926.svg");
	background-size: 16px 16px;
	background-position: 50% 26px;
	background-repeat: no-repeat;
	width: 50px;
	height: 60px;
	float: right;
	cursor: pointer;
}

.search-trigger:hover {
	background-image: url("/20180828132855/assets/images/search_797676.svg");
}

.form-field.header-search-field {
	transition: none;
}

.expanding-search {
	display: none;
	position: absolute;
	z-index: 1000;
	right: 4vw;
	padding: 20px;
	background-color: rgba(81, 12, 56, 0.9);
}

@media (max-width: 991px) {
	.expanding-search {
		right: 0;
	}
}

@media (max-width: 767px) {
	.expanding-search {
		width: 100%;
	}
}

.section-wrap.inside-content-section,
.homepage-wrapper {
	margin-top: 138px;
}

/* media queries */
@media (max-width: 1300px) {
	.nav-drop-wrap {
		margin: 0;
	}

	.logo-image {
		max-width: 300px;
	}

	.logo-link {
		width: 300px;
	}

	.logo-link {
		top: -21px;
	}

	.header-menu-wrap .nav-container {
		padding-left: 2vw;
		padding-right: 2vw;
	}

	.nav-drop-toggle-link {
		font-size: 1rem;
	}

	.section-wrap.inside-content-section,
	.homepage-wrapper {
		margin-top: 115px;
	}
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.footer-phone-link {
		display: inline-block;
	}

	.footer-phone-text {
		display: none;
	}

	.button.header-search-button {
		width: 20%;
		height: 2.5rem;
		margin-right: 0rem;
		font-size: 1rem;
	}

	.container.home-cb-container {
		padding: 1vw 30px 4vw;
	}

	.container.home-cb-container.home-intro-container {
		padding-bottom: 4vw;
	}

	.container.home-cb-container.lower-cta-container {
		padding-right: 10vw;
		padding-left: 10vw;
	}

	.container.home-cb-container.featured-container {
		padding-top: 2vw;
		padding-bottom: 1vw;
	}

	.container.footer-container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.footer-container.signoff-container {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.container.inside-container {
		padding: 25px 20px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100%;
		min-height: 0px;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 3vw;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1.25em 1.375rem;
		float: left;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
		text-align: left;
		letter-spacing: 0.02em;
		text-transform: none;
		background-image: none;
	}

	.nav-link:hover {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #c9c6c4;
	}

	.nav-link.w--current {
		background-color: rgba(0, 0, 0, 0.3);
		background-image: none;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #721926;
		color: #fff;
		text-align: left;
	}

	.nav-link.w--current:hover {
		background-image: none;
		color: hsla(0, 0%, 100%, 0.75);
	}

	.nav-link.two-part-mobile {
		width: 79.75%;
		float: left;
		background-image: none;
	}

	.nav-link.two-part-mobile:hover {
		color: hsla(0, 0%, 100%, 0.75);
		cursor: pointer;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: #fff;
		cursor: pointer;
	}

	.nav-link.mobile-list-back:hover {
		color: #fff;
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		max-width: 100%;
		margin-top: 0px;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		border-left-style: none;
		background-color: #2c2c2b;
		text-align: left;
	}

	.logo-link {
		top: 0px;
		max-width: 350px;
		width: 350px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 1.375rem;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.home-cbox-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-right-col {
		margin-top: 1.5rem;
		-webkit-justify-content: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.column.footer-signoff-left-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.logo-image {
		max-width: 350px;
	}

	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		padding: 0px 0.75rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 250ms ease;
		transition: all 250ms ease;
		color: #6b6b6b;
		line-height: 3.5rem;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		color: #313131;
		cursor: pointer;
	}

	.menu-button.w--open {
		background-color: transparent;
		color: #6b6b6b;
		cursor: pointer;
	}

	.menu-button.w--open:hover {
		color: #313131;
		cursor: pointer;
	}

	.footer-signoff-section {
		text-align: center;
	}

	.copyright-text {
		margin-left: 0rem;
	}

	.footer-signoff-list {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-signoff-grip {
		margin-left: 0em;
		float: none;
	}

	.footer-column-wrap {
		padding-bottom: 0rem;
	}

	.header-content-right-wrap {
		height: 100%;
		padding-right: 0vw;
		padding-bottom: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.header-contents-wrap {
		display: block;
		height: auto;
		border-bottom: 1px solid #c9c6c4;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: left;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.form-field.header-search-field {
		width: 80%;
		height: 2.5rem;
		padding-left: 3vw;
		font-size: 1rem;
	}

	.secondary-nav-list {
		display: none;
		height: 100%;
		margin-top: 0rem;
		margin-right: 0px;
		margin-bottom: 0em;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.right-side-col .box,
	.right-col-item-wrap {
		margin-left: 0em;
	}

	.slideshow-container {
		max-width: 991px;
	}

	.slider-mask.with-caption {
		padding-bottom: 200px;
	}

	.slider-arrow {
		width: 3rem;
	}

	.has-caption.slider-arrow {
		bottom: 8.5rem;
	}

	.slider-arrow.left-slider-arrow {
		margin-right: 3.5rem;
	}

	.slider-indicator {
		margin-right: 7rem;
	}

	.has-caption.slider-indicator {
		bottom: 8.5rem;
	}

	.home-image-link {
		max-height: 180px;
		margin-right: 0%;
	}

	.home-image-link.large-image-link {
		height: 25vw;
		margin-right: 0%;
	}

	.home-image-link.large-image-link.large-image-1 {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.home-content-box-wrap {
		margin-bottom: 1.5em;
		padding-bottom: 1.5em;
	}

	.home-content-box-wrap.large-content-box-wrap {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
		padding-bottom: 0em;
	}

	.home-content-box-title {
		font-size: 1.4125em;
	}

	.home-content-box-title.intro-cb-title {
		font-size: 2.5rem;
		line-height: 2.9rem;
	}

	.slider-caption-wrap {
		left: 0px;
		padding-right: 30px;
		padding-left: 30px;
		font-size: 1rem;
	}

	.inside-page-header-section {
		min-height: 200px;
		background-image: url('/20180828132855/assets/images/banner-tablet.jpg');
	}

	.main-content-wrapper {
		width: 100%;
		padding-top: 0vw;
	}

	.menu-button-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding-top: 1px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		color: #ff9934;
		font-size: 2.5rem;
		line-height: 3.5rem;
	}

	.menu-button-text {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		margin-left: 0.125rem;
		padding-top: 1px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #ff9934;
		font-size: 0.75rem;
		line-height: 3.5rem;
		letter-spacing: -0.02em;
	}

	.home-cb-text-wrap.lower-cta-wrap {
		padding: 1.25rem 2.5rem 2.5rem 3rem;
	}

	.slider-caption-wrap h1,
	.slider-caption-wrap h2,
	.slider-caption-wrap h3,
	.slider-caption-title {
		font-size: 1.4rem;
		line-height: 1.9rem;
	}

	.left-nav-wrapper {
		overflow: hidden;
		height: 0px;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1.25em;
		padding-bottom: 1.25em;
		float: right;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20180828132855/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), -1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: transparent;
		font-size: 0.875rem;
		line-height: 2rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.15);
		cursor: pointer;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		background-color: #494848;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-top: -1px;
		margin-right: 0.25em;
		float: left;
		background-image: url('/20180828132855/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.header-content-right-row {
		width: 100%;
		height: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		background-color: hsla(30, 1%, 43%, 0.1);
	}

	.header-search-wrap {
		margin-right: 0px;
		padding: 1rem 3vw;
		float: none;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border-top-width: 1px;
		border-top-color: rgba(0, 0, 0, 0.05);
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
	}

	.header-search-form {
		width: 100%;
	}

	.header-mobile-search {
		display: none;
		overflow: hidden;
		height: auto;
	}

	.search-trigger {
		margin-left: auto;
		background-image: url("/20180828132855/assets/images/search_797676.svg");
	}

	.search-trigger:hover {
		background-image: url("/20180828132855/assets/images/search_721926.svg");
	}

	.mobile-menu-content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.nav-search-button-icon {
		display: none;
	}

	.footer-social-icons-links-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}

	.footer-signoff-back-top-button {
		margin-left: 0rem;
		font-size: 0.75rem;
	}

	.last-content-box {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-image-link-text {
		padding-left: 5%;
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	.home-cb-text-wrap p,
	.home-cb-text-wrap.lower-cta-wrap p,
	.cta-paragraph {
		max-width: none;
	}

	.inside-content-wrapper {
		padding-bottom: 1rem;
	}

	.left-nav-col {
		display: none;
	}

	.content-wrapper {
		position: relative;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.right-side-col {
		width: 100%;
		margin-top: 1.5rem;
		margin-left: 0%;
	}

	.ecomm-header-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 14px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		font-size: 0.6875rem;
		font-weight: 500;
	}

	.ecomm-header-link.cart-icon {
		height: 2.5rem;
		margin-right: 0.5rem;
	}

	.ecomm-header-link.account-icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1.25rem;
	}

	.ecomm-header-wrap {
		margin-left: 30px;
	}

	.home-cb-text-wrap.intro-cbox-text-wrap {
		width: 100%;
	}

	.home-custom-cb-background {
		width: 100%;
		margin-left: 0;
		margin-top: 30px;
	}

	.container.home-cb-container.home-intro-container {
		display: block;
	}

	.slider-caption-title p {
		font-size: 1.4rem;
		line-height: 1.9rem;
	}

	.section-wrap.inside-content-section,
	.homepage-wrapper {
		margin-top: 115px;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 1rem;
	}

	h1 {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 2.1rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.home-cb-container {
		padding-right: 30px;
		padding-bottom: 3vw;
		padding-left: 30px;
	}

	.container.home-cb-container.home-intro-container {
		padding-top: 0vw;
	}

	.container.home-cb-container.featured-container {
		padding-bottom: 0vw;
	}

	.container.footer-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.inside-container {
		padding-right: 1rem;
		padding-bottom: 25px;
		padding-left: 1rem;
	}

	.nav-container {
		padding-bottom: 0.75rem;
	}

	.nav-link {
		font-size: 1rem;
	}

	.nav-link.two-part-mobile {
		width: 79.75%;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.15);
	}

	.nav-menu-wrap {
		background-color: #2c2c2b;
		background-image: none;
	}

	.logo-link {
		top: 0px;
		max-width: 300px;
	}

	.row.footer-row {
		margin-right: 10px;
		margin-left: 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.home-cbox-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.menu-button {
		padding-right: 0.6rem;
		padding-left: 0.6rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.footer-column-wrap {
		min-width: 35%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
		text-align: center;
	}

	.breadcrumb-list {
		font-size: 0.6875rem;
	}

	.secondary-nav-list {
		margin-right: 0px;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-side-col .box,
	.right-col-item-wrap {
		margin-left: 0rem;
	}

	.slideshow-container {
		position: static;
	}

	.slider-mask.with-caption {
		padding-bottom: 236px;
	}

	.slider-arrow {
		left: 30px;
		right: auto;
		bottom: auto;
		width: 2rem;
		height: 2rem;
		margin-top: -3rem;
		margin-left: 2.5rem;
	}

	.slider-arrow.left-slider-arrow {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.slider-indicator {
		left: 110px;
		top: auto;
		right: auto;
		bottom: auto;
		margin-top: -3.25rem;
		margin-right: 0rem;
		padding-right: 20px;
	}

	.slider-indicator:hover {
		background-color: transparent;
		cursor: pointer;
	}

	.has-caption.slider-arrow {
		bottom: auto;
	}

	.has-caption.slider-indicator {
		bottom: auto;
	}

	.home-image-link {
		max-height: 140px;
	}

	.home-image-link.large-image-link {
		width: 100%;
		height: 45vw;
		max-height: 260px;
		min-height: 150px;
		font-size: 1.5rem;
	}

	.home-image-link.large-image-link.large-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.home-content-box-wrap.large-content-box-wrap {
		border-bottom: 1px none rgba(0, 0, 0, 0.1);
	}

	.home-content-box-title.intro-cb-title {
		font-size: 2.25rem;
		line-height: 2.6rem;
	}

	.slider-caption-wrap {
		max-width: none;
		padding-right: 1.25rem;
		font-size: 1rem;
	}

	.main-content-wrapper {
		overflow: visible;
		padding-top: 0vw;
	}

	.main-content-wrapper.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.two-part-button-toggle {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.15), -1px 0 0 0 hsla(0, 0%, 100%, 0.15);
		line-height: 1.6875rem;
	}

	.footer-signoff-back-top-button {
		padding-top: 1em;
		padding-bottom: 1em;
		font-size: 0.625rem;
		line-height: 1.5em;
	}

	.last-content-box {
		margin-right: 10px;
		margin-left: 10px;
	}

	.home-image-link-text {
		padding-left: 1rem;
		font-size: 1.35rem;
	}

	.footer-paragraph {
		text-align: center;
	}

	.ecomm-header-link {
		padding-right: 0.5em;
		padding-left: 0.5em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.inside-page-header-section {
		background-image: url('/20180828132855/assets/images/banner-mobile.jpg');
	}

	.logo-image {
		max-width: 300px;
	}

	.section-wrap.inside-content-section,
	.homepage-wrapper {
		margin-top: 99px;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.9rem;
		line-height: 2.4rem;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.button.header-search-button {
		padding-right: 1em;
		padding-left: 1em;
	}

	.container.home-cb-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.home-cb-container.home-intro-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.home-cb-container.lower-cta-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.home-cb-container.featured-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.nav-container {
		padding-left: 1rem;
	}

	.nav-link {
		font-size: 0.9375rem;
	}

	.nav-link.two-part-mobile {
		width: 79%;
	}

	.logo-link {
		max-width: 215px;
		margin-right: 0px;
		padding-left: 0px;
	}

	.footer-section {
		text-align: center;
	}

	.column.footer-signoff-right-col {
		margin-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.column.footer-signoff-left-column {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.footer-signoff-list {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.footer-signoff-grip {
		margin: 1rem;
	}

	.footer-column-wrap {
		min-width: 50%;
		padding-right: 0em;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.header-content-right-wrap {
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.header-search-field {
		font-size: 0.875rem;
	}

	.secondary-nav-list {
		max-width: 100%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.slider-mask.with-caption {
		padding-bottom: 280px;
	}

	.slider-arrow {
		left: 20px;
	}

	.slider-indicator {
		left: 100px;
	}

	.home-image-link {
		height: 25vw;
		min-height: 100px;
		min-width: 35%;
		margin-right: 5%;
		float: none;
	}

	.home-image-link.large-image-link {
		max-height: 180px;
		min-height: 180px;
		margin-right: 0%;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-image-link.large-image-link.large-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.home-content-box-wrap.large-content-box-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-content-box-title.intro-cb-title {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	.slider-caption-wrap {
		padding-left: 1.25rem;
	}

	.menu-button-text {
		display: none;
	}

	.home-cb-text-wrap {
		width: 100%;
		float: none;
	}

	.home-cb-text-wrap.intro-cbox-text-wrap {
		padding-right: 0em;
		padding-left: 0em;
	}

	.home-cb-text-wrap.lower-cta-wrap {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.slider-caption-wrap h1,
	.slider-caption-wrap h2,
	.slider-caption-wrap h3,
	.slider-caption-title {
		max-width: 100%;
		text-align: left;
	}

	.footer-social-icons-section-wrap {
		width: 100%;
		padding-right: 0px;
		float: none;
	}

	.two-part-button-toggle {
		font-size: 0.8125rem;
	}

	.header-content-right-row {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.header-search-wrap {
		width: 100%;
		padding-right: 1rem;
		padding-left: 0vw;
	}

	.header-search-form {
		width: 100%;
	}

	.footer-social-icons-links-wrap {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-back-top-button {
		margin: 1em 1rem;
	}

	.last-content-box {
		margin-right: 0px;
		margin-left: 0px;
	}

	.ecomm-header-link {
		padding-right: 0.5em;
		padding-left: 0.25em;
	}

	.ecomm-header-link.cart-icon {
		padding-right: 0em;
	}

	.ecomm-header-link.account-icon {
		margin-right: 0.5rem;
	}

	.ecomm-header-wrap {
		margin-left: 1rem;
	}

	.logo-image {
		max-width: 215px;
	}

	.section-wrap.inside-content-section,
	.homepage-wrapper {
		margin-top: 85px;
	}
}

/* SET DEFAULT FONT SIZE */
html {
	font-size: 1rem;
}

/* FONT SMOOTHING */
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
::-moz-selection {
	opacity: 1;
	background-color: #494948;
	color: #ffffff;
}

/* Safari browser */
::selection {
	opacity: 1;
	background-color: #494948;
	color: #ffffff;
}

/* CONTROL PHONE NUMBER COLOR */
.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	border-bottom: 1px solid transparent;
	color: #fff;
}

.footer-section a[href^=tel]:hover {
	border-bottom: 1px solid #797676;
	color: #fb9a03;
	cursor: pointer;
}

/* REDUCE ELEMENTS SO PART OF SLIDESHOW CAPTION SHOWS ON A 1366x768 SCREEN */
@media screen and (min-width: 992px) and (max-height: 828px) {
	.nav-menu-wrap {
		margin-top: 10px;
		margin-bottom: 0px;
	}

	.header-content-right-wrap {
		height: 44px;
		padding-right: 3.2vw;
	}
}

.blockstyle-heading {
	-webkit-box-decoration-break: clone;
}

.skip-to-main-button {
	padding: 0 !important;
}

.skip-to-main-button:focus {
	position: absolute;
	text-indent: 0;
	height: auto;
	width: auto;
	z-index: 1001;
	padding: 7px 1.15rem 8px !important;
}

.mobile-navigation-menu {
	display: none;
}

.mobile-navigation-menu li.active .nav-link {
	background-color: rgba(0, 0, 0, 0.3) !important;
	box-shadow: inset 5px 0 0 0 #ff9934;
}

/*mmenu*/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mm-wrapper_blocking {
		overflow: hidden;
		height: 100%;
		margin: 0;
	}

	.mm-wrapper_blocking body {
		overflow: hidden;
		margin: 0;
	}

	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
		background-color: rgba(0, 0, 0, 0.5);
	}

	.mm-menu_offcanvas {
		max-width: none;
		background-color: #510c38;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 47px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.25em 1.375rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		text-transform: capitalize;
		float: none;
		box-shadow: none;
	}

	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: transparent;
		cursor: pointer;
	}

	.mm-menu a.w--current {
		box-shadow: inset 5px 0 0 0 #721926;
	}

	.mm-menu a:hover {
		color: hsla(0, 0%, 100%, 0.75);
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
		background-color: rgba(0, 0, 0, 0.15);
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: hsla(0, 0%, 100%, 0.25);
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: hsla(0, 0%, 100%, 0.5);
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		padding: 0 10px 0 40px;
		height: 67px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		height: 67px;
		border-bottom: 0 none !important;
	}

	.mm-navbar__title {
		font-size: 1.125rem;
		line-height: 1.375rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover {
		cursor: pointer;
	}

	.mm-navbar:hover .mm-navbar__title {
		color: #fff;
		cursor: pointer;
	}
}

/*inside pages*/
/*right sidebar*/
.box.documents .content > table {
	margin-bottom: 15px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 15px;
}

/*messages*/
.session-message-wrapper .success,
#session-message-wrapper .success {
	display: block;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #000;
	font-size: 1.125rem;
}

.session-message-wrapper .error,
#session-message-wrapper .error {
	display: block;
	background-color: #ffccd0;
	border: 2px solid #721926;
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #000;
	font-size: 1.125rem;
}

.session-message-wrapper .success p,
#session-message-wrapper .success p,
.session-message-wrapper .success ul li,
#session-message-wrapper .success ul li,
.session-message-wrapper .error p,
#session-message-wrapper .error p,
.session-message-wrapper .error ul li,
#session-message-wrapper .error ul li {
	margin-bottom: 0;
	margin-top: 0;
	font-weight: 400;
	font-size: 1.125rem;
	color: #000;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 0 0 0.75rem 0;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
	line-height: 1.125em;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #510c38;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
}

table.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #313131;
	border-bottom: 0px solid white;
	color: white;
	text-align: left;
}

table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0 none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.9375em;
	line-height: 1.4125em;
}

table.styled.striped tr:nth-child(even) {
	background: #eee;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875em;
	line-height: 1.42857143;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: #c9c6c4 !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: #c9c6c4;
	border: 0px;
	color: #c9c6c4;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*heading links*/
.left-sidebar-custom h1 a,
.content-wrapper h1 a,
.left-sidebar-custom h2 a,
.content-wrapper h2 a,
.left-sidebar-custom h3 a,
.content-wrapper h3 a,
.left-sidebar-custom h4 a,
.content-wrapper h4 a,
.left-sidebar-custom h5 a,
.content-wrapper h5 a,
.left-sidebar-custom h6 a,
.content-wrapper h6 a {
	color: inherit;
	font-family: inherit;
}

.left-sidebar-custom h1 a:hover,
.content-wrapper h1 a:hover,
.left-sidebar-custom h2 a:hover,
.content-wrapper h2 a:hover,
.left-sidebar-custom h3 a:hover,
.content-wrapper h3 a:hover {
	color: #fb9a03;
	cursor: pointer;
}

.left-sidebar-custom h4 a:hover,
.content-wrapper h4 a:hover,
.left-sidebar-custom h5 a:hover,
.content-wrapper h5 a:hover,
.left-sidebar-custom h6 a:hover,
.content-wrapper h6 a:hover {
	color: #fb9a03;
	cursor: pointer;
}

/* GRIP Logo */
#GRIPFooterLogoText {
	color: #fff !important;
}
